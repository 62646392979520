$(document).ready(function() {
  let form = $("#applicant-form");

  form.on("submit", function(e) {
    e.preventDefault();

    const action = form[0].action;
    const button = $("#applicant-button");
    let data = {};
    let fields = $(form).serializeArray().map(function(v) {return [v.name, v.value] });

    button.prop('disabled', true);

    fields.forEach(function(field) {
      data[field[0]] = field[1];
    });

    $.post(action, data, function(html) {}, "json").done(function(data) {
      form.clear_form_errors();
      form.trigger('reset');

      $('#contactError').removeClass('show');
      grecaptcha.reset();

      window.location = data.url;

    }).fail(function(response) {
      if(response.status === 422) {
        form.render_form_errors('applicant', response.responseJSON)
      }

      $('#contactError').addClass('show');
      grecaptcha.reset();
    }).always(function() {
      button.prop('disabled', false);
    });
  });
});

$.fn.render_form_errors = function(model_name, errors) {
    var form;
    form = this;
    this.clear_form_errors();
    return $.each(errors, function(field, messages) {
        var input, label;
        input = form.find('input, select, textarea').filter(function() {
            var name;
            name = $(this).attr('name');
            if (name) {
                return name.match(new RegExp(model_name + '\\[' + field + '\\(?'));
            }
        });
        input.closest('.form-group').addClass('field_with_errors');
        input.addClass('is-invalid');
        // label = $("label[for='" + input[0].id + "']");
        // return label.append("<span class='error-message ml-1'>" + messages.join(', ') + "</span>");
    });
};

$.fn.clear_form_errors = function() {
    this.find('.form-group').removeClass('field_with_errors');
    this.find('.form-control.is-invalid').removeClass('is-invalid');
    // return this.find('span.error-message').remove();
};
