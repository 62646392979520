$(document).ready(function() {
  let form = $("#contact-form");
  form.on("submit", function(e) {
    let action = form[0].action;
    let button = $("#contact-button");
    button.prop('disabled', true);
    e.preventDefault();
    var data = {};
    let fields = $(form).serializeArray().map(function(v) {return [v.name, v.value] });
    fields.forEach(function(field) {
      data[field[0]] = field[1];
    });
    $.post(action, data, function(html) {}, "json").done(function(data) {
      form.clear_form_errors();
      form.trigger('reset');
      $('#contactError').removeClass('show');
      $('#contactSuccess').addClass('show');
      grecaptcha.reset();
      if(data.id) {
        window.location = "/contact/" + data.token + "/details";
      }
    }).fail(function(response) {
      if(response.status==422) {
        form.render_form_errors('contact', response.responseJSON)
      } else {
      }
      $('#contactSuccess').removeClass('show');
      $('#contactError').addClass('show');
      grecaptcha.reset();
    }).always(function() {
      button.prop('disabled', false);
    });
  });

  let form_iframe = $("#iframe-form");
  form_iframe.on("submit", function(e) {
    let action_iframe = form_iframe[0].action;
    let button = $("#contact-button");
    button.prop('disabled', true);
    e.preventDefault();
    var data = {};
    let fields = $(form_iframe).serializeArray().map(function(v) {return [v.name, v.value] });
    fields.forEach(function(field) {
      data[field[0]] = field[1];
    });
    $.post(action_iframe, data, function(html) {}, "json").done(function(data) {
      form_iframe.clear_form_errors();
      form_iframe.trigger('reset');
      $('#contactError').removeClass('show');
      $('#contactSuccess').addClass('show');
      grecaptcha.reset();
    }).fail(function(response) {
      if(response.status==422) {
        form_iframe.render_form_errors('contact', response.responseJSON)
      } else {
      }
      $('#contactSuccess').removeClass('show');
      $('#contactError').addClass('show');
      grecaptcha.reset();
    }).always(function() {
      button.prop('disabled', false);
    });
  });
});

$.fn.render_form_errors = function(model_name, errors) {
    var form;
    form = this;
    this.clear_form_errors();
    return $.each(errors, function(field, messages) {
        var input, label;
        input = form.find('input, select, textarea').filter(function() {
            var name;
            name = $(this).attr('name');
            if (name) {
                return name.match(new RegExp(model_name + '\\[' + field + '\\(?'));
            }
        });
        input.closest('.form-group').addClass('field_with_errors');
        input.addClass('is-invalid');
        // label = $("label[for='" + input[0].id + "']");
        // return label.append("<span class='error-message ml-1'>" + messages.join(', ') + "</span>");
    });
};

$.fn.clear_form_errors = function() {
    this.find('.form-group').removeClass('field_with_errors');
    this.find('.form-control.is-invalid').removeClass('is-invalid');
    // return this.find('span.error-message').remove();
};
